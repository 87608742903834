<template>
  <div v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <div class="sub-con"></div>
    <div class="sub-menu">
      <Loader v-if="showLoader" />
      <div class="d-flex">
        <div class="page-name page-tile primary-color">Homepage</div>
        <div class="custome-con">
          <div class="dropdown-custome menu-select button-reg dd-menu-items">
            <button class="btn btn-secondary dropdown-toggle custome-btn" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img alt="Customize" src="../../assets/setting.svg" />
              <span class="ft-14 primary-color customise"> Customize </span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <div class="form-check">
                  <input class="form-check-input chek" type="checkbox" value="1" id="gap-closure"
                    @change="tabStatus($event, 1, 'gapClosure')" v-model="menuItems.gapClosure" />
                  <span class="geekmark"></span>
                  <label class="form-check-label"> Gap Closure</label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input class="form-check-input chek" type="checkbox" value="5" id="requires-action-items"
                    @change="tabStatus($event, 5, 'requiresAction')" v-model="menuItems.requiresAction" />
                  <label class="form-check-label"> Off Track Pillars</label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input class="form-check-input chek" type="checkbox" value="7" id="machine-replication​"
                    @change="tabStatus($event, 7, 'machineReplication')" v-model="menuItems.machineReplication" />
                  <label class="form-check-label">Machine Replication Key Constraints</label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input class="form-check-input chek" type="checkbox" value="2" id="assiigned-eduction"
                    @change="tabStatus($event, 2, 'assiignedEduction')" v-model="menuItems.assiignedEduction" />
                  <label class="form-check-label">Assigned Education</label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input class="form-check-input chek" type="checkbox" value="3" id="action-utems"
                    @change="tabStatus($event, 3, 'actionItems')" v-model="menuItems.actionItems" />
                  <label class="form-check-label"> Action Items</label>
                </div>
              </li>
              <li class="dd-last-li" v-if="!(loggedinUserRole.length == 1 && loggedinUserRole.includes(roles.USER))">
                <div class="form-check">
                  <input class="form-check-input chek" type="checkbox" value="4" id="team-progress"
                    @change="tabStatus($event, 4, 'myTeam')" v-model="menuItems.myTeam" />
                  <label class="form-check-label" for="flexCheckDefault"> My Team </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="tab-nav d-flex">
          <div v-if="menuItems.gapClosure" class="in-action ponter-cur main-tab-nact" :class="{
              'primary-color': activecard == 1,
              'active-tab': activecard == 1,
              'main-tab-act': activecard == 1
            }" @click="goto('gap-closure', 1)">
            <div class="">Gap Closure</div>
            <hr :class="{ 'active-tab-scroll-hr': activecard == 1 }" class="opacity-zero" />
          </div>
          <div v-if="menuItems.requiresAction && !(loggedinUserRole.length == 1 && loggedinUserRole.includes(roles.USER))"
            class="in-action ponter-cur main-tab-nact" :class="{
                'primary-color': activecard == 5,
                'active-tab': activecard == 5,
                'main-tab-act': activecard == 5
              }" @click="goto('requires-action-items', 5)">
            <div class="">Off Track Practices</div>
            <hr :class="{ 'active-tab-scroll-hr': activecard == 5 }" class="opacity-zero" />
          </div>
          <div v-if="menuItems.machineReplication" class="in-action ponter-cur main-tab-nact" :class="{
              'primary-color': activecard == 6,
              'active-tab': activecard == 6,
              'main-tab-act': activecard == 6
            }" @click="goto('machine-replication', 6)">
            <div class="">Machine Replication Key Constraints</div>
            <hr :class="{ 'active-tab-scroll-hr': activecard == 6 }" class="opacity-zero" />
          </div>

          <div v-if="menuItems.assiignedEduction" class="in-action ponter-cur main-tab-nact" :class="{
              'primary-color': activecard == 2,
              'active-tab': activecard == 2,
              'main-tab-act': activecard == 2
            }" @click="goto('assiigned-eduction', 2)">
            <div class="">Assigned Education</div>
            <hr :class="{ 'active-tab-scroll-hr': activecard == 2 }" class="opacity-zero" />
          </div>
          <div v-if="menuItems.actionItems" class="in-action ponter-cur main-tab-nact" :class="{
              'primary-color': activecard == 3,
              'active-tab': activecard == 3,
              'main-tab-act': activecard == 3
            }" @click="goto('action-items', 3)">
            <div class="">Action Items</div>
            <hr :class="{ 'active-tab-scroll-hr': activecard == 3 }" class="opacity-zero" />
          </div>
          <div v-if="menuItems.myTeam && !(loggedinUserRole.length == 1 && loggedinUserRole.includes(roles.USER))"
            class="in-action ponter-cur main-tab-nact" :class="{
                'primary-color': activecard == 4,
                'active-tab': activecard == 4,
                'main-tab-act': activecard == 4
              }" @click="goto('team-progress', 4)">
            <div class="">My Team</div>
            <hr :class="{ 'active-tab-scroll-hr': activecard == 4 }" class="opacity-zero" />
          </div>
        </div>
        <hr class="sub-menu-devider" />
      </div>
    </div>
    <div style="display: none" v-if="gapClosureDataStatus && !menuItems.gapClosure">
      <div class="primary-color title-text center-aligned">selectedYear Global Maturity Assessment Gap Closure Dashboard
      </div>

      <PlantChart :gapClosureData="gapClosureData" />
    </div>
    <div class="body-container">
      <div class="container-fluid action-item">
        <div v-if="menuItems.gapClosure && (checkPersonaForPPM || checkPersonaForGPM || checkPersonaForRPM)"
          class="go-action-card" ref="gap-closure" id="printable">
          <div class="card-title card-title-cl card-main-tl">Gap Closure</div>
          <div class="gap-closure-header d-flex">
            <div class="d-flex gap-closure-header-sub-con">
              <div class="in-action" v-if="checkPersonaForGPM">
                <div class="primary-color card-tab-act">Global PO Metrics</div>
                <hr class="active-tab-scroll-hr" />
              </div>
              <div class="in-action" v-if="checkPersonaForRPM && !checkPersonaForGPM">
                <div class="primary-color card-tab-act">Regional PO Metrics</div>
                <hr class="active-tab-scroll-hr" />
              </div>
              <div class="in-action" v-if="checkPersonaForPPM && !checkPersonaForRPM && !checkPersonaForGPM">
                <div class="primary-color card-tab-act">Plant PO Metrics</div>
                <hr class="active-tab-scroll-hr" />
              </div>
            </div>
            <div class="d-flex gap-closure-header-sub-con gap-closure-header-action">
              <div class="table-view" :class="{ 'active-view': gapClosureType == 1 }" @click="changeGapClosureType(1)">
                <img alt="active graph" v-if="gapClosureType == 2" src="../../assets/Graph_view_blue.svg" />
                <img alt="inactive graph" v-if="gapClosureType == 1" src="../../assets/Graph_view_white.svg" />
              </div>
              <div class="grap-view" @click="changeGapClosureType(2)" :class="{ 'active-view': gapClosureType == 2 }">
                <img alt="active table" v-if="gapClosureType == 1" src="../../assets/Table_view_blue.svg" />
                <img alt="inactive table" v-if="gapClosureType == 2" src="../../assets/Table_view_white.svg" />
              </div>
              <div class="print-view action-btn" @click="print">
                <img alt="print" src="../../assets/print.svg" />
              </div>
              <div class="download-view action-btn" @click="downloadPdf">
                <img alt="download" src="../../assets/download_icon.svg" />
              </div>
            </div>
          </div>
          <hr class="gp-hr" />
          <div v-if="gapClosureType == 1" ref="plant-chart">
            <div id="chart-header" class="primary-color title-text center-aligned"
              v-if="checkPersonaForPPM && !checkPersonaForRPM && !checkPersonaForGPM && gapClosureData">
              {{ gapClosureData.currentYear }} Plant Dashboard - Maturity Assessment Gap Closure
            </div>
            <div id="chart-header" class="primary-color title-text center-aligned"
              v-else-if="!checkPersonaForPPM && checkPersonaForRPM && !checkPersonaForGPM && gapClosureData">
              {{ gapClosureData.currentYear }} Regional Dashboard - Maturity Assessment Gap Closure
            </div>
            <div id="chart-header" class="primary-color title-text center-aligned" v-else>{{ gapClosureData.currentYear }}
              Global Dashboard - Maturity Assessment Gap Closure</div>

            <PlantChart v-if="gapClosureDataStatus" :gapClosureData="gapClosureData" />

            <div class="sample-list">
              <div class="prior-years-actual"></div>
              <span class="sample-span">Prior Years Actual</span>
              <div class="committed"></div>
              <span class="sample-span">AOP Target</span>
              <div class="on-track"></div>
              <span class="sample-span">Completed</span>
              <div class="aop-values"></div>
              <span class="sample-span">Off Track</span>
            </div>
          </div>
          <div id="print-chart"></div>
          <div class="gc-table" id="gc-table" v-if="gapClosureType == 2">
            <b-table id="gc-table-id" class="gn-table gc-t-view" responsive :items="gcTvData" :fields="gcTvField">
            </b-table>
          </div>
          <div class="row pillar-con">
            <div class="col-lg-1 col-md-3 col-sm-3 pillar-tm mt-1" v-for="pillarData in gapClosureData.pillarData"
              v-bind:key="pillarData.pillarId">
              <div class="pillar-st gp-pillar" @click="pillarClick(pillarData.pillarId)" :class="{
                  'on-pillar': pillarData.pillarStatus == pillarStatus.ONTRACK,
                  'off-pillar': pillarData.pillarStatus == pillarStatus.OFFTRACK,
                  'non-pillar': pillarData.pillarStatus == pillarStatus.NOTACTIVE
                }">
                {{ pillarData.pillarCode }}
              </div>
              <span v-if="pillarData.pillarStatus != pillarStatus.NOTACTIVE"
                class="position-absolute top-0 start-100 badge rounded-pill bg-danger pillar-tic badge-cl">
                <img alt="ontrack" v-if="pillarData.pillarStatus == pillarStatus.ONTRACK"
                  src="../../assets/blue-tick.svg" />
                <img alt="offtrack" v-if="pillarData.pillarStatus == pillarStatus.OFFTRACK"
                  src="../../assets/off-track.svg" />
              </span>
            </div>
          </div>
          <div class="track-tic-con" v-if="gapClosureDataStatus">
            <span> <img alt="on track" src="../../assets/blue-tick.svg" /><span class="on-off-tk"> On Track</span></span>
            <span class="mr-off-tic"> <img alt="off track" src="../../assets/off-track.svg" /><span class="on-off-tk"> Off
                Track</span></span>
          </div>
        </div>
        <div class="container-fluid action-item">
          <div v-if="menuItems.requiresAction" class="row action-item-row" ref="requires-action-items">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="card card-cont">
                <div class="card-body requires-action-item-card">
                  <div class="requires-action-header">
                    <div class="card-title card-title-cl card-main-tl">Off Track Practices</div>
                  </div>
                  <div class="plant-dd">
                    <label for="pillar" class="form-label select-lable lable-text">Pillars</label>
                    <select class="form-control select-text select-text-pd cust-st-icon" id="pillar"
                      :disabled="!this.offTrackPIllarList.length" v-model="requiredPillarSelected"
                      @change="pillarChanged($event)">
                      <option value="">Select Pillar</option>
                      <option v-for="pillar in offTrackPIllarList" v-bind:key="pillar.pillarId" :value="pillar.pillarId">
                        {{ pillar.pillarName }}, {{ pillar.pillarCode }}</option>
                    </select>
                  </div>
                </div>
                <b-modal id="action-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
                  <ActionHomeScreenFilter :hideModal="hideModal" :noOfFilters="noOfFilters" :filterArray="filterArray"
                    :filterSelected="filterSelected" :applyFilter="applyFilter" :resetFilter="resetFilter"
                    :onContext="onContext" :changedFilter="changedFilter" :onSelect="() => { }" :onRemove="() => { }"
                    :filterHeader="'Filters'" />
                </b-modal>
                <b-table sticky-header id="my-table" class="action-table gn-table requires-action-table" striped
                  responsive hover v-if="requiredPillarPlants.length" :items="requiredPillarPlants"
                  :fields="requiredActionFields" :per-page="perPage" :current-page="currentPage"
                  :sort-by.sync="actionItemsSortBy" :sort-desc.sync="actionItemsSortDesc">
                  <template #cell(regionCode)="row">
                    {{ row.item.regionCode }}
                  </template>

                  <template #cell(plantName)="row">
                    {{ row.item.plantName }}
                  </template>

                  <template #cell(actions)="row">
                    <div class="mm-menu" style="width: 2rem">
                      <div class="maturity-dd dropstart" style="width: 2rem">
                        <b-button v-b-modal.modal-center class="btn btn-primary" type="button" style="
                            border-color: #869ed8 !important;
                            background: #eff3ff 0 0 no-repeat padding-box !important;
                            color: #274ba7 !important;
                            border-radius: 50px;
                            width: 100px;
                            font-size: 13px;
                          " @click="viewPlant(row.item)">
                          View Practice
                        </b-button>
                      </div>
                    </div>
                  </template>
                </b-table>
                <div>
                  <b-modal id="modal-center" size="xl" centered v-if="this.selectedPlant" :title="getModalTitle()"
                    :ok-only="true" :hide-header-close="true" ok-title="Close" header-class="modal-title"
                    modal-class="modal-font">
                    <div style="display: flex; justify-content: space-between">
                      <h4 style="padding-top: 1.25rem">Practice</h4>
                      <div class="plant-dd">
                        <label for="pillar" class="form-label select-lable lable-text">Year</label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="year"
                          v-model="selectedRequiredYear" @change="yearChanged($event)">
                          <option value="">Select Year</option>
                          <option v-for="year in requiredYearList" v-bind:key="year.id" :value="year.value">
                            {{ year.value }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="this.principleData.length">
                      <b-tabs content-class="mt-3">
                        <b-tab v-for="(principle, key) in this.principleData" :key="'dyn-tab-' + key" :title="principle.principleNumber + '.' + principle.compiledInfo.levelId + '.' + principle.compiledInfo.actionDetails.actionName + ' ' + principle.principleName
                          ">
                          <div>
                            <h3>Practice: {{ principle.principleName }}</h3>
                          </div>
                          <div style="white-space: pre-line; font-size: 16px">
                            <h3>
                              Status:
                              <span :class="actionStatusClass(principle)">
                                <strong> {{ principle.compiledInfo.actionStatus }}</strong>
                              </span>
                            </h3>
                          </div>
                          <h3>Level: {{ principle.compiledInfo.levelId }}</h3>
                          <div style="white-space: pre-line; font-size: 16px">
                            Owner:
                            {{ principle.compiledInfo.actionDetails.ownerName }}
                          </div>
                          <div style="white-space: pre-line; font-size: 16px">
                            Committed Date:
                            {{ principle.compiledInfo.actionDetails.committedDate }}
                          </div>
                          <div style="white-space: pre-line; font-size: 16px">
                            Completion Date:
                            {{ principle.compiledInfo.actionDetails.completedDate }}
                          </div>
                          <div style="white-space: pre-line; font-size: 16px">
                            Target Completion Date:
                            {{ principle.compiledInfo.actionDetails.targetCompletionDate }}
                          </div>
                          <h4 style="margin-top: 20px">Description:</h4>
                          <div
                            style="white-space: pre-line; font-size: 16px; border: 1px solid; border-radius: 4px; padding: 8px 32px 16px 8px; margin-top: 20px">
                            {{ principle.compiledInfo.actionDetails.actionDescription }}
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                    <div v-if="this.fetchingPrincipleData && !Object.keys(this.principleData).length"
                      style="text-align: center; font-size: 24px; color: #274ba7">
                      <b-spinner label="Loading..."></b-spinner> Loading Data...
                    </div>

                    <div v-else-if="!Object.keys(this.principleData).length"
                      style="text-align: center; font-size: 24px; color: #274ba7">No Records Found</div>
                  </b-modal>
                </div>
                <div class="no-data" v-if="this.requiredPillarPlants.length === 0">
                  <img alt="no data" src="../../assets/No_Data.svg" />
                  <span class="ed-date" v-if="this.requiredPillarSelected === ''">Please Select a Pillar to View
                    Records</span>
                  <span class="ed-date" v-else-if="this.requiredPillarPlants.length === 0">No Records Found</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ?Machine Replication -->
        <div v-if="menuItems.machineReplication" class="education-tab" ref="machine-replication">
          <div class="education-row">
            <div class="card card-cont edu-card">
              <div class="card-body">
                <span class="card-title card-title-cl card-main-tl"> Machine Replication Key Constraints - {{ freezedFor
                }} </span>
                <div class="gap-closure-header d-flex">
                  <div class="d-flex gap-closure-header-sub-con">
                    <div class="in-action" v-if="checkPersonaForGPM">
                      <div class="primary-color card-tab-act">Global PO Metrics</div>
                      <hr class="active-tab-scroll-hr" />
                    </div>
                    <div class="in-action" v-if="checkPersonaForRPM && !checkPersonaForGPM">
                      <div class="primary-color card-tab-act">Regional PO Metrics</div>
                      <hr class="active-tab-scroll-hr" />
                    </div>
                    <div class="in-action" v-if="checkPersonaForPPM && !checkPersonaForRPM && !checkPersonaForGPM">
                      <div class="primary-color card-tab-act">Plant PO Metrics</div>
                      <hr class="active-tab-scroll-hr" />
                    </div>
                  </div>
                  <div class="d-flex gap-closure-header-sub-con gap-closure-header-action">
                    <div class="download-view action-btn" @click="downloadMachineReplication">
                      <img alt="download" src="../../assets/download_icon.svg" />
                    </div>
                  </div>
                </div>
                <hr class="gp-hr" />
                <div id="machine-replication" ref="machine-replication-div">
                  <MachineReplication />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ?Machine Replication -->

        <div v-if="menuItems.assiignedEduction" class="education-tab" ref="assiigned-eduction">
          <div class="education-row">
            <div class="card card-cont edu-card">
              <div class="card-body">
                <span class="card-title card-title-cl card-main-tl">Assigned Education</span>
                <div class="training-assigned card-third-tl">{{ this.assignedCourses.length }} Training Assigned</div>
                <div v-if="assignedCourses.length > 0">
                  <Education :dataArr="pendingCourseShow"> </Education>
                </div>
              </div>
              <div v-if="assignedCourses.length == 0" class="no-data">
                <img alt="no data" src="../../assets/No_Data.svg" />
                <span class="ed-date">No Records Found</span>
              </div>

              <div class="ft-12 primary-color see-all-detais ed-details-btn" v-if="assignedCourses.length > 0">
                <span class="ponter-cur" @click="$router.push('/educationHomePage')">See All Details</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="menuItems.actionItems" class="row action-item-row" ref="action-items">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="card card-cont">
              <div class="card-body action-item-card">
                <div class="action-header">
                  <div class="card-title card-title-cl card-main-tl">Action Items</div>
                </div>
                <div class="table-header d-flex">
                  <div class="in-action card-tab-act" v-for="status in statusData" :key="status.actionStatusId"
                    :value="status.actionStatusId"
                    @click="(selected = status.actionStatusId), (selectedTabName = status.actionStatusName), getActionItemsData()"
                    :class="{ 'primary-color': selected == status.actionStatusId }">
                    <div class="" @click="(selected = status.actionStatusId), getActionItemsData()">
                      {{ status.actionStatusName }}
                    </div>
                    <hr :class="{
                      'active-tab-hr': selected == status.actionStatusId
                    }" />
                  </div>

                  <div class="acti-item-filter funnel-con">
                    <div class="action-btn primary-color" v-b-modal.action-filter>
                      <b-icon icon="funnel"></b-icon>
                    </div>
                  </div>
                </div>
                <hr class="act-tab-dr" />
                <b-modal id="action-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
                  <ActionHomeScreenFilter :hideModal="hideModal" :noOfFilters="noOfFilters" :filterArray="filterArray"
                    :filterSelected="filterSelected" :applyFilter="applyFilter" :resetFilter="resetFilter"
                    :onContext="onContext" :changedFilter="changedFilter" :onSelect="() => { }" :onRemove="() => { }"
                    :filterHeader="'Filters'" />
                </b-modal>
                <b-table sticky-header id="my-table" class="action-table gn-table" striped responsive hover :items="items"
                  :fields="fields" :per-page="perPage" :current-page="currentPage" :sort-by.sync="actionItemsSortBy"
                  :sort-desc.sync="actionItemsSortDesc">
                  <template #cell(commitedDate)="row">
                    {{ row.item.commitedDate | dateFormater }}
                  </template>
                  <template #cell(actionItemDescription)="row">
                    <div>
                      {{ actionDescriptionT(row.item.actionItemDescription) }}
                    </div>
                  </template>
                  <template #cell(completedDate)="row">
                    {{ row.item.completedDate | dateFormater }}
                  </template>

                  <template #cell(actions)="row" style="text-align: right">
                    <div class="mm-menu" style="width: 2rem">
                      <div class="dropdown-custome dd-menu-items maturity-dd dropstart" style="width: 2rem">
                        <button class="btn btn-secondary" type="button" id="dropdownList" data-bs-toggle="dropdown"
                          aria-expanded="false" style="padding: 0; margin: 0"
                          @click="(plantId = row.item.plantId), (actionIDs = row.item.actionIdd), (pillar = row.item.pillarCode), actionData()">
                          <img alt="more" id="dropdownList" src="../../assets/More.svg" />
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownList" style="text-align: left">
                          <li @click="viewAction()" style="padding-left: 0">
                            <button style="background: none; border: none">View</button>
                          </li>
                          <li>
                            <button @click="editAction()" id="edit-li"
                              :disabled="!checkPersonaActionEdit || actionData()">Edit</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </b-table>
                <div v-if="items.length > 3" class="row pagination-con">
                  <div class="col-lg-4 col-md-12 col-sm-12 record-info">
                    {{ showingData() }}
                  </div>

                  <div class="col">
                    <b-pagination class="pagination-align record-info" v-model="currentPage"
                      :total-rows="totalActionItems" :per-page="perPage" aria-controls="my-table" prev-text="Back"
                      next-text="Next" first-text="First" last-text="Last" @click.native="nextPageData(currentPage)">
                    </b-pagination>
                    <div class="per-page-cont record-info">
                      <select v-model="perPage" class="per-page" aria-label="Default select example">
                        <option v-if="count > 3" :value="3">3</option>
                        <option v-if="count > 5" :value="5">5</option>
                        <option v-if="count > 10" :value="10">10</option>
                        <option v-if="count > 50" :value="50">50</option>
                        <option else :value="count">{{ count }}</option>
                      </select>
                      Per page
                    </div>
                  </div>
                </div>
                <div class="no-data" v-if="items.length === 0">
                  <img alt="no data" src="../../assets/No_Data.svg" />
                  <span class="ed-date">No Records Found</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="menuItems.myTeam && !(loggedinUserRole.length == 1 && loggedinUserRole.includes(roles.USER))"
          ref="team-progress">
          <div>
            <educationMyTeam> </educationMyTeam>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Education from "../Education/educationHome.vue";
import educationMyTeam from "../Education/educationMyTeam.vue";
import "../../common/css/CommonStyle.css";
import "../../common/css/font-categories.css";
import PlantChart from "./PlantChart.vue";
import MyTeam from "./MyTeam.vue";
import EducationPieChart from "./EducationPieChart.vue";
import { mapGetters } from "vuex";
import { Printd } from "printd";
import Api from "../../common/constants/api";
import ApiCalls from "../../common/api/apiCalls";
import PDFMake from "../../common/helper/PDFMake.vue";
import Helper from "../../common/helper/";
import Loader from "../../components/common/Loader.vue";
import pillarStatus from "../../common/constants/pillarStatus";

import Role from "../../common/constants/roles";
import html2canvas from "html2canvas";
import MachineReplication from "../../views/MachineReplication/MachineReplicationHomeScreen.vue";
import ActionHomeScreenFilter from "../MaturityModel/ActionTracker/ActionHomeScreenFilter.vue";
import moment from "moment";
import MachineSpiderChart from "../MachineReplication/Reports/MachineSpiderChart.vue";
import SpiderChartReport from "@/common/helper/SpiderChartReport.vue";

export default {
  name: "Dashboard",
  data() {
    return {
      pendingCourseShow: [],
      assignedCourses: [],
      selectedStatus: "Committed",
      action_item3: [],
      parentActionItems: [],
      LastpageNum: 0,
      action_item2: [],
      pageNumber: 1,
      statusName: "",
      count: 0,
      actionItemDrop: [],
      plantId: "",
      pillar: "",
      levelId: "",
      practiceId: "",
      statusId: "",
      principleIds: "",
      pillarCode: "",
      principleNumber: "",
      plantName: "",
      actionIDs: "",
      roles: Role,
      obj: new Printd(),
      gapClosureType: 1,
      gapClosuretab: true,
      machineReplication: true,
      assiignedEduction: true,
      actionItems: true,
      teamProgress: true,
      globalPOActionItems: true,
      activecard: 1,
      actionFilterStatus: false,
      activeDiv: 0,
      actionItemsSortBy: "actionTitle",
      actionItemsSortDesc: false,
      selected: 4,
      selectedTabName: "",
      statusData: [],
      action_item: [],
      totalRecords: "",
      pageIndex: "",
      totalPage: "",
      hasNextPage: "",
      hasPreviousPreviousPage: "",
      pillarIDD: 1,
      filterSelected: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        {
          key: "actionIdd",
          sortable: false,
          label: "",
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "plantId",
          sortable: false,
          label: "",
          thClass: "d-none",
          tdClass: "d-none"
        },
        // {
        //   key: "actionID",
        //   label: "#",
        //   sortable: false,
        // },

        {
          key: "pillarCode",
          sortable: true,
          label: "Pillar"
        },
        {
          key: "actionID",
          label: "#",
          sortable: true
        },

        {
          key: "pillarName",
          label: "Pillar Name",
          sortable: true
        },
        // {
        //   key: "actionItemDescription",
        //   label: "Action Title",
        //   sortable: true,
        // },
        // {
        //   key: "ownerName",
        //   label: "Owner",
        //   sortable: false,
        // },
        // {
        //   key: "commitedDate",
        //   label: "Committed Date",
        //   sortable: true,
        // },
        // {
        //   key: "completedDate",
        //   label: "Completed Date",
        //   sortable: true,
        // },
        {
          key: "actions",
          label: "",
          sortable: false
        }
      ],
      noOfFilters: 2,
      filterArray: [],
      backupActionItems: [],
      items: [
        {
          actionIdd: "",
          plantId: "",
          pillarCode: "",
          actionID: "",
          actionItemDescription: "",
          ownerName: "",
          commitedDate: "",
          completedDate: ""
        }
      ],
      perPage: 10,
      currentPage: 1,
      chart_data: [
        {
          name: "Lorem",
          total: 30,
          learningColor: "#DC5656"
        },
        {
          name: "Ipsum",
          total: 21,
          learningColor: "#3BD761"
        },
        {
          name: "Dolor",
          total: 20,
          learningColor: "#E7DD09"
        }
      ],
      tabsToShow: [1, 2, 3, 4, 5],
      gcTvField: [],
      freezedFor: moment(new Date()).format("MMM-YYYY"),
      gcTvData: [],
      poActionData: {},
      poActionDataStatus: false,
      gapClosureData: {},
      gapClosureDataStatus: false,
      pillarStatus: pillarStatus,
      showLoader: false,
      loggedinUserRole: "",
      spiderData: {
        label: [
          "MIXER",
          "EXTR THREAD",
          "EXTRA SWALL",
          "FABR CAL",
          "GUM CAL",
          "WIRE CAL",
          "PLY CUT",
          "BKR SHEAR",
          "BEAD SHEAR",
          "BEAD WINDER",
          "TBM",
          "CURE",
          "FVM",
          "DBM",
          "EXTR APEX",
          "EXTR APEXER",
          "EXTR DPLX",
          "EXTR QPLX",
          "TBM 2STG_LMC",
          "TBM 2STG_HMC",
          "TBM FULL STG_HMC"
        ],
        datasets: [
          {
            label: "% CAP Utilize",
            data: [4, 4, 4, 3, 3, 3, 3, 4, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
            fill: true,
            pillarN: "People and Environmental Care",
            pillarId: 1
          },
          {
            label: "South Africa, EMEA",
            data: [3, 4, 4, 3, 4, 3, 3, 4, 3, 3, 3, 3, 4, 3, 3, 3, 3, 4, 3, 3],
            fill: true
          }
        ],
        principleList: [
          "1. Management Leadership Commitment",
          "2. EHS policy",
          "3. Governance",
          "4. Roles, Responsibilities and Accountability",
          "5. Associate Engagement",
          "6. Planning: Tactical and strategic (EHS Objective and Planning)",
          "7. Hazard Identification and Risk Assessment",
          "8. Investigation of Incidents, learnings and follow up",
          "9. Auditing, EHS Site Inspection, and Observation",
          "10. Industrial Hygiene",
          "11. Chemical Management",
          "12. Environmental Aspect",
          "13. Legal Compliance and other requirements",
          "14. Hazard prevention and control",
          "15. Management of Change",
          "16. Contractor Management",
          "17. Emergency Preparedness and Response",
          "18. Serious Incident Prevention Program",
          "19. Training, competency(Continuous Skills development)",
          "20. Program Evaluation, and Continual Improvement"
        ],
        plantId: 11,
        pillarId: 1,
        regionId: 2
      },
      requiredActionItems: [],
      requiredActionFields: [
        {
          key: "regionCode",
          sortable: true,
          label: "Region"
        },
        {
          key: "plantName",
          label: "Plant Name",
          sortable: true
        },
        {
          key: "actions",
          label: "",
          sortable: false
        }
      ],

      requiredActionCount: 0,
      practices_Count: {},
      selectedPlant: {},
      principleData: [],
      compiledInfo: {},
      selectedPlantAction: {},
      plantList: [],
      requiredActionPlantId: 1,
      offTrackPIllarList: [],
      samplePillarList: [],
      requiredPillarSelected: "",
      requiredPillarPlants: [],
      requiredYearList: [
        { id: 1, value: "2020" },
        { id: 2, value: "2021" },
        { id: 3, value: "2022" }
      ],
      selectedRequiredYear: new Date().getFullYear(),
      fetchingPrincipleData: false
    };
  },
  components: {
    PlantChart,
    MyTeam,
    EducationPieChart,
    Education,
    educationMyTeam,
    MachineReplication,
    Printd,
    ActionHomeScreenFilter,
    Loader,
    MachineSpiderChart
  },
  mixins: [PDFMake, SpiderChartReport],
  computed: {
    ...mapGetters(["menuItems"]),
    checkMSAL() {
      let oidc = JSON.parse(sessionStorage.getItem("userProfile"));
      console.log(this.$oidc, this.$msalInstance, oidc, "MSALLlllllll");
      return !!(oidc && oidc.isAuthnticated);
    },
    totalActionItems() {
      return this.items.length;
    },
    checkPersonaForGPM() {
      return !!(
        this.$oidc.userProfile.roles.includes(this.roles.CORPORATE) ||
        this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) || 
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER)
      );
    },
    checkPersonaForRPM() {
      return !!(this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER));
    },
    checkPersonaForPPM() {
      return !!(
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTSUBPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.USER)
      );
    },
    checkPersonaForPOActionItemsTable() {
      return !!(
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.CORPORATE)
      );
    },
    checkPersonaActionEdit() {
      return !!(
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTSUBPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.USER)
      );
    }
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 1,
      subMenu: 0
    });
  },
  created() {
    let day = 7;
    let year = new Date().getFullYear();
    this.freezedFor = new Date().getDate() <= 7 ? moment(new Date(year, new Date().getMonth() - 1, day)).format("MMM-YY") : moment(new Date()).format("MMM-YY");
    setTimeout(() => {
      this.showLoader = false;

    }, 10000);
    if (this.$oidc.userProfile && this.$oidc.userProfile.roles.length <= 0) {
      this.$router.push("/unauthorized");
    }
    this.selected = Helper.getstatusname();
    this.getLoggedInUserData();
    this.getActionStatusData();
    this.getPOActionItems();
    this.getGapClosureData();
    this.getActionItemsData();
    this.courseDataForPending();
    this.getRequiresActionItemsData();
    this.getPlantList();
    this.getOfftrackPillarList();
    this.selected = Helper.getstatusname();
    this.loggedinUserRole = this.$oidc.userProfile.roles;

    this.requiredYearList = []
    for (let i = 2020; i <= new Date().getFullYear(); i++) {
      this.requiredYearList.push({ id: i - 2019, value: i })
    }

  },
  methods: {
    pillarClick(pillarId) {
      console.log(pillarId)
      this.requiredPillarSelected = pillarId
      this.pillarChanged({ target: { value: pillarId } })
    },
    async getOfftrackPillarList() {
      let newPillarList = [];
      const pillarList = await ApiCalls.httpCallDetails(Api.GETPILLARDROPDOWNLIST, "get");
      const plantList = await ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get");
      newPillarList = pillarList?.data;
      let offtrackList = [];
      plantList.data.forEach((plant) => {
        let url = `${Api.PLANTPILLARDETAILS}${plant.plantId}`;
        ApiCalls.httpCallDetails(url, "get")
          .then((plantDetailData) => {
            plantDetailData.data.forEach((plntData) => {
              if (plntData.maturityAssesmentStatus === "OFFTRACK") {
                const indexTest = newPillarList.findIndex((pillar) => pillar.pillarId === plntData.pillarId);
                if (plntData.pillarId === newPillarList[indexTest].pillarId) {
                  const parsedOBJ = JSON.parse(
                    JSON.stringify({
                      ...plant,
                      pillarId: plntData.pillarId
                    })
                  );
                  offtrackList.push(parsedOBJ);
                }
              }
            });
          })
          .then(() => {
            offtrackList.length &&
              newPillarList.forEach((pillar, key) => {
                offtrackList.forEach((offtrack) => {
                  if (pillar.pillarId === offtrack.pillarId) {
                    newPillarList[key] = {
                      ...newPillarList[key],
                      plants: {
                        ...newPillarList[key].plants,
                        [offtrack.plantId]: offtrack
                      }
                    };
                  }
                });
              });
            this.offTrackPIllarList = newPillarList;
          });
      });
    },
    courseDataForPending() {
      this.showLoader = true;
      this.assignedCourses = [];
      let url = Api.LEARNINGASSIGNMENT + 1000 + "?Type=pending";
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        if (data.success) {
          this.assignedCourses = data.data.result;
          this.assignedCourses.forEach((element) => {
            element.title = element.title + " (" + element.componentId.split("_").pop() + ")";
            element.shortName = "" + element.componentId.split("_").pop() + "";
            if (element.requiredDate) element.requiredDate = element.requiredDate ? new Date(element.requiredDate) : null;
          });
          this.pendingCourseShow = this.assignedCourses.slice(0, 4);
          this.showLoader = false;
        }
      });
    },
    getLoggedInUserData() {
      ApiCalls.httpCallDetails(Api.GETLOGGEDINUSERDATA, "get").then((data) => {
        this.$store.dispatch("loggedInUserData", data.data);
      });
    },
    nextPageData(Page) {
      this.action_item2 = this.action_item;
      if (this.count % this.perPage > 0 && this.count % this.perPage < this.perPage) {
        this.LastpageNum = parseInt(this.count / this.perPage) + 1;
      } else if (this.count % this.perPage == 0) {
        this.LastpageNum = parseInt(this.count / this.perPage);
      } else {
        this.LastpageNum = parseInt(this.count / this.perPage) + (this.count % this.perPage);
      }

      if (this.currentPage == this.LastpageNum && this.hasNextPage === true) {
        this.pageNumber++;
        ApiCalls.httpCallDetails(Api.DASHBOARDACTIONITEMS + this.pageNumber + "&PageSize=1000" + "&statusId=" + this.selected, "get").then((data) => {
          let arr = [];
          this.totalRecords = data.totalRecords;
          this.pageIndex = data.pageIndex;
          this.totalPage = data.totalPage;
          this.hasNextPage = data.hasNextPage;
          this.hasPreviousPage = data.hasPreviousPage;
          let selectedStatus = this.selected;
          let cnt = 0;
          this.action_item = this.action_item2.concat(data.data);
          this.action_item3 = this.action_item2.concat(data.data);
          if (this.action_item3.length > 0) {
            this.action_item3.forEach(function (element) {
              if (element.statusId == selectedStatus) {
                cnt++;
                let pillarCode = element.pillarCode;
                let principalNumber = element.principalNumber;
                let levelId = element.levelId;
                let actionName = element.actionName;
                let actionItemDescription = element.actionItemDescription;
                let ownerName = element.ownerName;
                let commitedDate = element.commitedDate;
                let completedDate = element.completedDate;
                let plantId = element.plantId;
                let actionId = element.actionId;
                let obj = {
                  actionIdd: actionId,
                  plantId: plantId,
                  pillarCode: pillarCode,
                  actionID: principalNumber + "." + levelId + "." + actionName,
                  actionItemDescription: actionItemDescription,
                  ownerName: ownerName,
                  commitedDate: commitedDate,
                  completedDate: completedDate
                };
                arr.push(obj);
              }
            });
          }
          this.parentActionItems = arr;
          this.items = arr;

          this.count = this.items.length;

          this.currentPage = this.LastpageNum;
          this.backupActionItems = arr;
          let pillarCode = arr.map((value) => value.pillarCode);
          pillarCode = pillarCode.filter(function (item, pos) {
            return pillarCode.indexOf(item) == pos;
          });
          let ownerName = arr.map((value) => value.ownerName);
          ownerName = ownerName.filter(function (item, pos) {
            return ownerName.indexOf(item) == pos;
          });
          this.filterArray = [
            {
              labelName: "Pillar",
              arrayValue: pillarCode,
              id: "Pillar"
            },
            {
              labelName: "Committed Date",
              arrayValue: [],
              id: "CommittedDate",
              forTargetDate: true
            },
            {
              labelName: "Completed Date",
              arrayValue: [],
              id: "CompletedDate",
              forCompletedDate: true
            }
          ];
          this.filterSelected = this.filterArray;
        });
      }
    },
    showingData() {
      let showData = this.perPage * this.currentPage;
      let showFirstData = showData - this.perPage;
      if (showData > this.count) {
        showData = this.count;
      }
      showFirstData = showFirstData + 1;
      return "Showing " + showFirstData + " to " + showData + " of " + this.count + " records";
    },
    async viewPlant(selectedPlant) {
      this.principleData = [];
      this.selectedPlant = selectedPlant;
      try {
        this.fetchingPrincipleData = true;
        const { data: principleListResult } = await this.getPrincipleList(selectedPlant);

        let principleArray = [];
        principleListResult.forEach(({ principleId, principleNumber, principleName, practiceData }) => {
          if (practiceData?.length) {
            practiceData.forEach(({ actionItems, levelId, practiceDescription }) => {
              if (actionItems?.length) {
                actionItems?.forEach(async (actItems) => {
                  let compiledInfo = {};
                  let url = `${Api.GETVIEWACTIONITEMS}/${actItems.actionId}/${selectedPlant.plantId}`;
                  let result = await ApiCalls.httpCallDetails(url, "get", "arraybuffer");
                  if ((!actItems.completedDate || !result?.data?.actualCompletionDate) && new Date(result?.data?.targetCompletionDate) < new Date()) {
                    principleArray.push({
                      principleId,
                      principleNumber,
                      principleName,
                      compiledInfo: {
                        ...compiledInfo,
                        actionDetails: {
                          ...result?.data,
                          targetCompletionDate: moment(result?.data?.targetCompletionDate).format("LL"),
                          committedDate: actItems.committedDate ? moment(actItems.committedDate).format("LL") : "N/A",
                          completedDate: actItems.completedDate ? moment(actItems.completedDate).format("LL") : "N/A"
                        },
                        actionStatus: result?.data?.status === "In Progress" ? "Off Track" : result?.data?.status,
                        practiceDescription,
                        levelId
                      }
                    });

                    principleArray.sort((a, b) => {
                      if (a.principleNumber < b.principleNumber) {
                        return -1;
                      }
                      if (a.principleNumber > b.principleNumber) {
                        return 1;
                      }
                      return 0;
                    });
                    this.principleData = principleArray;
                  } else {
                    // TODO document why this block is empty
                  }
                  if (a.principleNumber > b.principleNumber) {
                    return 1;
                  }
                  return 0;
                });
                this.principleData = principleArray;
              } else {
              }
            });
          }
        });
        // }
        //   });
        setTimeout(() => (this.fetchingPrincipleData = false), 5000);
      } catch (error) {
        throw error;
      }
    },
    viewActionItem() {
      let statusIdH = "";
      for (const element of this.action_item) {
        if (this.actionIDs == element.actionId) {
          statusIdH = element.statusId;
        }
      }
      Helper.setstatusname(statusIdH);
      this.$router.push("/view-action/action/" + this.actionID + "/" + this.requiredActionPlantId + "/" + this.pillar);
    },
    viewAction() {
      this.$router.push("/view-action/DashboardAction/" + this.actionIDs + "/" + this.plantId + "/" + this.pillar);
    },
    actionData() {
      for (const element of this.action_item) {
        if (this.actionIDs == element.actionId) {
          this.statusName = element.statusName;
        }
      }
      return !!(this.statusName == "Completed" || this.statusName == "completed");
    },
    editAction() {
      let statusIdH = "";
      let pillarName = "";
      for (const element of this.action_item)
        if (this.actionIDs == element.actionId) {
          this.plantId = element.plantId;
          this.pillar = element.pillarId;
          this.principleIds = element.principleId;
          this.pillarCode = element.pillarCode;
          pillarName = element.pillarName;
          this.levelId = element.levelId;
          this.plantName = " ";
          this.practiceId = element.practiceId;
          this.$statusName = element.statusName;
          this.statusId = element.statusId;
          statusIdH = element.statusId;
          console.log(pillarName);
        }

      Helper.setstatusname(statusIdH);
      this.$router.push(
        "/add-new-action/" +
        this.plantId +
        "/" +
        this.pillar +
        "/" +
        this.principleIds +
        "/" +
        parseInt(this.practiceId) +
        "/" +
        this.actionIDs +
        "/" +
        this.levelId +
        "/" +
        this.pillarCode +
        "/" +
        this.plantName +
        "/" +
        this.statusId +
        "/" +
        "Dashboard-action"
      );
    },
    actionDescriptionT(actionDesc) {
      return actionDesc?.length <= 50 ? actionDesc : actionDesc.substr(0, 50) + "...";
    },
    changedFilter(event) {
      let filterSelectedC = [];
      this.filterArray.forEach((val, index) => {
        if (val.id === event.target.id && event.target.value !== val.id && val.arrayValue.includes(event.target.value)) {
          let temp = {
            id: val.id,
            arrayValue: [event.target.value],
            labelName: val.labelName
          };
          filterSelectedC.push(temp);
          return filterSelectedC;
        } else if (val.id === event.target.id && val.id === event.target.value) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName
          };
          filterSelectedC.push(temp);

          return filterSelectedC;
        } else if (val.id !== event.target.id && event.target.value !== val.id && val.arrayValue.length === this.filterSelected[index]["arrayValue"].length) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName
          };
          filterSelectedC.push(temp);

          return filterSelectedC;
        } else {
          filterSelectedC.push(this.filterSelected[index]);
        }
      });
      this.filterSelected = filterSelectedC;
    },
    onContext(event, pos, noCompletedDate) {
      this.checkNoCompletedDate = noCompletedDate;
      if (event && event.selectedDate) {
        switch (pos) {
          case 0:
            this.TargetStartDateInput = event.selectedFormatted;

            break;
          case 1:
            this.TargetEndDateInput = event.selectedFormatted;

            break;
          case 2:
            this.CompletedStartDateInput = event.selectedFormatted;

            break;
          case 3:
            this.CompletedEndDateInput = event.selectedFormatted;

            break;

          default:
            break;
        }
      }
    },

    applyFilter() {
      let filteredItems = [];
      let filterSelected = this.filterSelected;
      if (filterSelected.length > 0 && filterSelected[0] && filterSelected[0]["arrayValue"].length > 0) {
        filteredItems = this.backupActionItems.filter(({ pillarCode }) => filterSelected[0]["arrayValue"].includes(pillarCode));
      }
      // console.log(filteredItems);
      //for target date
      if (
        this.filterSelected.length > 0 &&
        this.filterSelected[1] &&
        this.filterSelected[1]["arrayValue"].length > 0
        // (this.TargetStartDateInput || this.TargetEndDateInput)
      ) {
        filteredItems = filteredItems.filter(({ commitedDate }) => {
          // filterSelected[1]["arrayValue"].includes(ownerName)

          let cd = new Date(commitedDate);
          let toCompareStart = new Date(this.filterSelected[1]["arrayValue"][0].startInput);
          let toCompareEnd = new Date(this.filterSelected[1]["arrayValue"][1].endInput);
          if (this.filterSelected[1]["arrayValue"][0].startInput && this.filterSelected[1]["arrayValue"][1].endInput) {
            return !!(moment(cd).isAfter(toCompareStart) && moment(toCompareEnd).isAfter(cd));
          } else if (this.filterSelected[1]["arrayValue"][0].startInput && !this.filterSelected[1]["arrayValue"][1].endInput) {
            return !!moment(cd).isAfter(toCompareStart);
          } else if (!this.filterSelected[1]["arrayValue"][0].startInput && this.filterSelected[1]["arrayValue"][1].endInput) {
            return !!moment(toCompareEnd).isAfter(cd);
          } else {
            return true;
          }
        });
      }
      // console.log(filteredItems);
      //for completed date
      if (
        this.filterSelected.length > 0 &&
        this.filterSelected[2] &&
        this.filterSelected[2]["arrayValue"].length > 0
        // (this.TargetStartDateInput || this.TargetEndDateInput)
      ) {
        filteredItems = filteredItems.filter(({ completedDate }) => {
          // filterSelected[1]["arrayValue"].includes(ownerName)

          let cd = new Date(completedDate);
          let toCompareStart = new Date(this.filterSelected[2]["arrayValue"][0].startInput);
          let toCompareEnd = new Date(this.filterSelected[2]["arrayValue"][1].endInput);
          if (this.filterSelected[2]["arrayValue"][0].startInput && this.filterSelected[2]["arrayValue"][1].endInput) {
            return !!(moment(cd).isAfter(toCompareStart) && moment(toCompareEnd).isAfter(cd));
          } else if (this.filterSelected[2]["arrayValue"][0].startInput && !this.filterSelected[2]["arrayValue"][1].endInput) {
            return !!moment(cd).isAfter(toCompareStart);
          } else if (!this.filterSelected[2]["arrayValue"][0].startInput && this.filterSelected[2]["arrayValue"][1].endInput) {
            return !!moment(toCompareEnd).isAfter(cd);
          } else {
            return true;
          }
        });
      }

      this.items = JSON.parse(JSON.stringify(filteredItems));
      this.count = this.items.length;
      this.perPage = this.count >= 10 ? 10 : this.count;
      this.hideModal();
    },
    resetFilter() {
      this.items = this.backupActionItems;
      this.count = this.items.length;
      this.perPage = this.count >= 10 ? 10 : this.count;
      this.hideModal();
    },
    downloadPdf() {
      this.download = true;
      if (parseInt(this.gapClosureType) === 1) this.pdfmake("plant-chart", "Gap Closure");
      else {
        Helper.export_table_to_excel("gc-table-id", "Dashboard Data");
      }
    },
    downloadMachineReplication() {
      this.download = true;
      this.pdfMakeCall("machineReplication", ["machine-replication"]);
    },
    print() {
      if (this.gapClosureType == 1) {
        this.printChartNew();
      }
      if (this.gapClosureType == 2) {
        this.printTable();
      }
    },
    printTable() {
      let cssText = `
            .table {
                width: 100%;
                color: #212529;
            }
            table {
                border-collapse: collapse;
            }
            #gc-table-id{
              font-family: sans-serif;
            }
            #gc-table-id thead th,
            #gc-table-id td {
              text-align: center !important;
              vertical-align: middle !important;
            }
            #gc-table-id th,
            td {
              border: 1px solid #efefef !important;
            }
            #gc-table-id tbody tr:nth-child(odd) {
              background-color: #fafafa !important;
            }
            #gc-table-id th:first-child,
            #gc-table-id td:first-child {
              font-family: "BarlowM", sans-serif  !important;
            }
            #gc-table-id th {
              font-weight: 100;
              font-family: "BarlowR", sans-serif ;
            }

            `;
      this.obj.print(document.getElementById("gc-table-id"), [cssText]);
    },
    printMTable() {
      let cssText = `
                .align-center {
                    text-align: center;
                    justify-content: center;
                }

                .title-text {
                    font: 1.16rem "BarlowR" , sans-serif ;
                    padding: 0 0 2rem 0;
                }

                .graph-div {
                    width: 100%;
                    display: flex;
                }

                .center-aligned {
                    text-align: center;
                    padding: 0 !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .legend {
                    margin: 0 0.5rem;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 1rem;
                }

                .primary-legend {
                    background-color: #e33737;
                }

                .secondary-legend {
                    background-color: #e6a40d;
                }

                .tertiary-legend {
                    background-color: #69c0b5;
                }

                .cmg-name {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    word-break: break-all;
                }

            `;

      this.obj.print(this.$refs["machine-replication"], [cssText]);
      setTimeout(function () {
        placeIn.innerHTML = "";
      }, 200);
    },
    printChart() {
      let cssText = `
         .box{
            background-color:#999;
          }
          .title-text {
            font: 1.33rem "BarlowSb", sans-serif;
            padding: 0 0 2rem 0;
          }
          .center-aligned {
            text-align: center;
            padding: 0 !important;
          }
          #canvasImg{
            width:100%
          }
          `;
      let headerEle = document.getElementById("chart-header").innerHTML;
      let canvasEle = document.getElementById("plant-chart");
      let placeIn = document.getElementById("print-chart");
      placeIn.innerHTML = "<div class='box'>" + headerEle + "<img id='canvasImg' src='" + canvasEle.toDataURL() + "' />" + "</div>";
      this.obj.print(placeIn, [cssText]);
      setTimeout(function () {
        placeIn.innerHTML = "";
      }, 200);
    },
    printChartNew() {
      html2canvas(this.$refs["plant-chart"], {
        logging: true,
        letterRendering: true,
        allowTaint: false,
        useCORS: true,
        scale: 1920 / window.innerWidth,
        backgroundColor: null
      })
        .then((canvas) => {
          let pdfMake = require("pdfmake/build/pdfmake.js");
          if (pdfMake.vfs == undefined) {
            let pdfFonts = require("pdfmake/build/vfs_fonts.js");
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
          }
          let div = document.createElement("div");
          //           <div style="
          //     height: 100%;
          //     width: 100%;
          // "><img a src="./download (1).png" class="block" style="
          //     width: 100%;
          //     margin-top: 12%;
          // "></div>
          div.className = "block1";
          let img = new Image();
          img.className = "block2";
          img.src = canvas.toDataURL("image/jpg");
          let cssText = `
                .block1 {
                  height: 100%;
                  width: 100%;
                }
                .block2 {
                  margin-top: 12%;
                  width: 100%;
                }`;
          div.appendChild(img);
          this.obj.print(div, [cssText]);
        })
        .catch((error) => { });
    },

    getActionItemsData() {
      let url = `${Api.DASHBOARDACTIONITEMS}${this.pageNumber}&PageSize=1000&statusId=${this.selected}`;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        if (data.success === true) {
          let arr = [];
          this.totalRecords = data.totalRecords;
          this.pageIndex = data.pageIndex;
          this.totalPage = data.totalPage;
          this.hasNextPage = data.hasNextPage;
          this.hasPreviousPage = data.hasPreviousPage;
          let selectedStatus = this.selected;
          let cnt = 0;
          this.action_item = data.data;

          if (this.action_item.length > 0) {
            this.action_item.forEach(function (element) {
              if (element.statusId == selectedStatus) {
                cnt++;
                let pillarCode = element.pillarCode;
                let principalNumber = element.principalNumber;
                let levelId = element.levelId;
                let actionName = element.actionName;
                let actionItemDescription = element.actionItemDescription;
                let ownerName = element.ownerName;
                let commitedDate = element.commitedDate;
                let completedDate = element.completedDate;
                let plantId = element.plantId;
                let actionId = element.actionId;
                let obj = {
                  actionIdd: actionId,
                  plantId: plantId,
                  pillarCode: pillarCode,
                  actionID: principalNumber + "." + levelId + "." + actionName,
                  actionItemDescription: actionItemDescription,
                  ownerName: ownerName,
                  commitedDate: commitedDate,
                  completedDate: completedDate
                };
                arr.push(obj);
              }
            });
          }
          this.parentActionItems = arr;
          this.items = arr;
          this.count = cnt;
          if (cnt < 10) {
            this.perPage = cnt;
          } else {
            this.perPage = 10;
          }
          this.setFilterArr(arr);
        }
      });
    },
    getActionItem({ pillarId, pillarInfo }) {
      let compiledInfo = {
        actionID: "",
        plantID: 0,
        pillarId
      };
      let actionList = [];
      (pillarInfo?.length && pillarInfo).forEach(({ practiceData }) => {
        if (practiceData?.length) {
          practiceData.forEach(({ actionItems, practiceDescription }) => {
            if (actionItems?.length) {
              actionItems?.forEach(async (actItems) => {
                let url = `${Api.GETVIEWACTIONITEMS}/${actItems.actionId}/1`;
                let result = await ApiCalls.httpCallDetails(url, "get", "arraybuffer");
                compiledInfo = {
                  ...compiledInfo,
                  actionDetails: { ...result?.data },
                  practiceDescription,
                  plantID: 1
                };
                actionList.push(compiledInfo);
              });
            }
          });
        }
      });

      return actionList;
    },
    async getRequiresActionItemsData() {
      let url = `${Api.PLANTPILLARDETAILS}${this.requiredActionPlantId}`;
      let data = await ApiCalls.httpCallDetails(url, "get");

      try {
        if (data) {
          let arr = [];
          this.showLoader = true;
          const { success, data: actionItemList } = data;
          if (success) {
            let itemData = {};
            (actionItemList?.length && actionItemList).forEach(async (item) => {
              if (item?.maturityAssesmentStatus === "OFFTRACK") {
                itemData = {
                  pillarId: item?.pillarId,
                  pillarCode: item?.pillarCode,
                  pillarName: item?.pillarName
                };
                itemData = {
                  ...itemData
                };
                arr.push(itemData);
                this.showLoader = false;
              }
            });
            this.requiredActionItems = arr?.length ? arr : [];
            this.requiredActionCount = this.requiredActionItems.length;
          }
        }
      } catch (error) {
        throw error;
      }
    },
    async getPrincipleList({ pillarId, plantId }) {
      try {
        const url = `${Api.GETACTIONITEM}${plantId}/${pillarId}/${this.selectedRequiredYear}?SortCategory=&&Order=`;
        const result = await ApiCalls.httpCallDetails(url, "get");
        return result;
      } catch (error) {
        throw error;
      }
    },
    getModalTitle() {
      return `${this.requiredPillarDetails?.pillarCode} - ${this.selectedPlant?.plantName}, ${this.selectedRequiredYear}` || "Plant Details";
    },
    getTabTitle() {
      return this.selectedPlant?.principleData?.principleName || "Principle Title";
    },
    actionStatusClass(principle) {
      return principle.compiledInfo.actionStatus === "Off Track" ? "off-track-label" : "";
    },
    getPlantList() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
        this.plantList = data.data;
        let plantId = this.selectedPlantAction ? this.selectedPlantAction : this.plantList[0].plantId;
        let index = 0;
        if (this.selectedPlantAction) index = this.plantList.findIndex((el) => el.plantId == this.selectedPlantAction);
        else if (this.loggedInUserData.plantId) {
          let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
          if (plantIndex > -1) {
            index = plantIndex;
            plantId = this.plantList[index].plantId;
          }
        } else if (this.loggedInUserData.regionName) {
          let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
          if (plantIndex > -1) {
            index = plantIndex;
            plantId = this.plantList[index].plantId;
          }
        }
        this.plantId = this.plantList[index];
        this.$store.dispatch("selectedPlantAction", plantId);
        this.showLoader = false;
      });
    },
    plantChanged(event) {
      this.requiredActionPlantId = event.target.value;
      this.selectedPlantAction = event.target.value;
      this.showLoader = true;
      this.principleData = [];
      this.getRequiresActionItemsData();
      let index = this.plantList.findIndex((data) => data.plantId === this.selectedPlantAction);
      this.plantName = index !== -1 ? this.plantList[index].plantName + "," + this.plantList[index].regionCode : "";
    },
    pillarChanged(event) {
      let procArr = [];
      this.requiredPillarSelected = event.target.value;
      let pillarIndex = this.offTrackPIllarList.findIndex((sample) => sample.pillarId === parseInt(event.target.value));
      this.requiredPillarDetails = this.offTrackPIllarList[pillarIndex];
      if (event.target.value) {
        if (this.offTrackPIllarList[pillarIndex].hasOwnProperty("plants")) {
          let selectedPlants = this.offTrackPIllarList[pillarIndex].plants;
          Object.keys(selectedPlants).forEach((sampleKeys) => {
            procArr.push(selectedPlants[sampleKeys]);
          });

          this.requiredPillarPlants = procArr;
        } else {
          this.requiredPillarPlants = [];
        }
      } else {
        this.requiredPillarPlants = [];
      }
    },
    yearChanged(event) {
      this.principleData = [];
      this.selectedRequiredYear = event.target.value;
      this.yearChangeHanlder();
    },
    async yearChangeHanlder() {
      try {
        this.fetchingPrincipleData = true;
        const { data: principleListResult } = await this.getPrincipleList(this.selectedPlant);
        let principleArray = [];
        principleListResult.forEach(({ principleId, principleNumber, principleName, practiceData }) => {
          if (practiceData?.length) {
            practiceData.forEach(({ actionItems, levelId, practiceDescription }) => {
              if (actionItems?.length) {
                actionItems?.forEach(async (actItems) => {
                  let compiledInfo = {};
                  let url = `${Api.GETVIEWACTIONITEMS}/${actItems.actionId}/${this.selectedPlant.plantId}`;
                  let result = await ApiCalls.httpCallDetails(url, "get", "arraybuffer");
                  if ((!actItems.completedDate || !result?.data?.actualCompletionDate) && new Date(result?.data?.targetCompletionDate) < new Date()) {
                    principleArray.push({
                      principleId,
                      principleNumber,
                      principleName,
                      compiledInfo: {
                        ...compiledInfo,
                        actionDetails: {
                          ...result?.data,
                          targetCompletionDate: moment(result?.data?.targetCompletionDate).format("LL"),
                          committedDate: actItems.committedDate ? moment(actItems.committedDate).format("LL") : "N/A",
                          completedDate: actItems.completedDate ? moment(actItems.completedDate).format("LL") : "N/A"
                        },
                        actionStatus: result?.data?.status === "In Progress" ? "Off Track" : result?.data?.status,
                        practiceDescription,
                        levelId
                      }
                    });

                    principleArray.sort((a, b) => {
                      if (a.principleNumber < b.principleNumber) {
                        return -1;
                      }
                      if (a.principleNumber > b.principleNumber) {
                        return 1;
                      }
                      return 0;
                    });
                    this.principleData = principleArray;
                  }
                });
              }
            });
          }
        });
        setTimeout(() => (this.fetchingPrincipleData = false), 7000);
      } catch (error) {
        console.log(error);
      }
    },
    setFilterArr(arr) {
      this.backupActionItems = arr;
      let pillarCode = arr.map((value) => value.pillarCode);
      pillarCode = pillarCode.filter(function (item, pos) {
        return pillarCode.indexOf(item) == pos;
      });
      let ownerName = arr.map((value) => value.ownerName);
      ownerName = ownerName.filter(function (item, pos) {
        return ownerName.indexOf(item) == pos;
      });
      this.filterArray = [
        {
          labelName: "Pillar",
          arrayValue: pillarCode,
          id: "Pillar"
        },
        {
          labelName: "Committed Date",
          arrayValue: [
            { startInput: null, startDate: null },
            { endInput: null, endDate: null }
          ],
          id: "CommittedDate",
          forTargetDate: true
        }
      ];
      if (this.selectedTabName === "Completed") {
        this.filterArray.push({
          labelName: "Completed Date",
          arrayValue: [
            { startInput: null, startDate: null },
            { endInput: null, endDate: null }
          ],
          id: "CompletedDate",
          forTargetDate: true
        });
      }
      this.filterSelected = this.filterArray;
    },
    getActionStatusData() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.DASHBOARDACTIONSTATUS, "get").then((data) => {
        this.statusData = data.data;
        this.statusData = data?.data?.filter((item, i) => {
          if (item.actionStatusId != 23 && item.actionStatusId != 4) return item;
        });
        this.showLoader = false;
      });
    },
    changeGapClosureType(type) {
      this.gapClosureType = type;
    },
    tabStatus(event, id, tab) {
      let menuItems = this.menuItems;
      let status = false;
      if (!event.target.checked && this.tabsToShow.length == 1) {
        status = true;
        console.log(status);
        switch (id) {
          case 1:
            setTimeout(() => {
              this.gapClosuretab = true;
              menuItems[tab] = event.target.checked;
              this.$store.dispatch("menuItems", menuItems);
            }, 0.1);
            break;
          case 2:
            setTimeout(() => {
              this.assiignedEduction = true;
              menuItems[tab] = event.target.checked;
              this.$store.dispatch("menuItems", menuItems);
            }, 0.1);
            break;
          case 3:
            setTimeout(() => {
              this.actionItems = true;
              menuItems[tab] = event.target.checked;
              this.$store.dispatch("menuItems", menuItems);
            }, 0.1);
            break;
          case 4:
            setTimeout(() => {
              this.teamProgress = true;
              menuItems[tab] = event.target.checked;
              this.$store.dispatch("menuItems", menuItems);
            }, 0.1);
            break;
          case 5:
            setTimeout(() => {
              this.requiresAction = true;
              menuItems[tab] = event.target.checked;
              this.$store.dispatch("menuItems", menuItems);
            }, 0.1);
            break;
          case 6:
            setTimeout(() => {
              this.globalPOActionItems = true;
              menuItems[tab] = event.target.checked;
              this.$store.dispatch("menuItems", menuItems);
            }, 0.1);
            break;
          case 7:
            setTimeout(() => {
              this.machineReplication = true;
              menuItems[tab] = event.target.checked;
              this.$store.dispatch("menuItems", menuItems);
            }, 0.1);
            break;
          default:
            break;
        }

        return;
      }
      if (!status) {
        menuItems[tab] = event.target.checked;
        this.$store.dispatch("menuItems", menuItems);
      }
      const index = this.tabsToShow.indexOf(id);
      if (index > -1) {
        this.tabsToShow.splice(index, 1);
      } else {
        this.tabsToShow.push(id);
      }
    },
    goto(refName, cardNumber) {
      this.activecard = cardNumber;
      let element = this.$refs[refName];
      let top = element.offsetTop - 200;
      window.scrollTo(0, top);
    },
    showActionFilter() {
      this.actionFilterStatus = true;
    },
    update(item, button) { },
    actionToggle(id) {
      this.activeDiv = id;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    getPOActionItems() {
      ApiCalls.httpCallDetails(Api.POACTIONITEMS, "get").then((data) => {
        if (data.data) {
          this.poActionData = data.data;
          this.poActionDataStatus = true;
        } else {
          this.poActionDataStatus = false;
        }
      });
    },
    goToPlantAsmt(id) {
      this.$store.dispatch("plantSelectedInAsmtHome", id);
      this.$router.push("/assessment");
    },
    getGapClosureData() {
      ApiCalls.httpCallDetails(Api.GAPCLOSURE, "get").then((data) => {
        this.showLoader = false;
        if (data.data) {
          let toAdd = 0;
          for (let i = 2; i < data.data.completed.length; i++) {
            data.data.completed[i] += toAdd;
            data.data.committed[i] += toAdd;
          }
          this.gapClosureData = data.data;
          this.gapClosureData.selectedYear = new Date().getFullYear();
          this.gapClosureDataStatus = true;
          this.gcTvField = [
            {
              key: "key",
              sortable: false,
              label: "Month"
            }
          ];
          this.gcTvData = [
            {
              key: "Completed Maturity Assessment Practices"
            },
            {
              key: "Committed Maturity Assessment Practices"
            }
          ];
          for (let index = 0; index < this.gapClosureData.label.length; index++) {
            let lable = {
              key: this.gapClosureData.label[index],
              sortable: false
            };
            this.gcTvField.push(lable);
            this.gcTvData[0][this.gapClosureData.label[index]] = this.gapClosureData.completed[index];
            this.gcTvData[1][this.gapClosureData.label[index]] = this.gapClosureData.committed[index];
          }
        } else {
          this.gapClosureDataStatus = false;
        }
      });
    }
  }
};
</script>
<style scoped>
.sample-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.sample-span {
  margin-right: 1rem;
  font: 1.08rem "BarlowM", sans-serif;
  color: #313131;
}

.ed-date {
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
}

.title-text {
  font: 1.33rem "BarlowSb", sans-serif;
  padding: 0 0 2rem 0;
}

.center-aligned {
  text-align: center;
  padding: 0 !important;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.maturity-dd ul {
  width: 13rem !important;
}

.maturity-dd ul li {
  padding-left: 1.5rem !important;
}

.maturity-dd button {
  font-size: 1.33rem;
  font-family: "BarlowM", sans-serif;
}

.mm-menu {
  margin-right: 0rem;
}

#edit-li:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

#edit-li {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

.action-item {
  padding: 0 !important;
  margin-top: 18px;
}

.card-title-cl {
  color: #234372;
  /* font-size: 0.875em;
  font-weight: 600; */
}

.training-assigned {
  color: #0e770a;
}

.trainig-image {
  margin-top: 16px;
}

.update-bt {
  background-color: #eff3ff !important;
  border-color: #96ace2 !important;
  color: #274ba7 !important;
}

.update-bt:hover {
  background-color: #e3e8f7 !important;
  border-color: #96ace2 !important;
  color: #274ba7 !important;
}

#my-table th div {
  color: #313131;
}

#my-table tbody tr:nth-child(odd) {
  background-color: #fafafa !important;
}

#my-table tbody tr:nth-child(even):hover {
  background-color: #fff !important;
}

#my-table tr td:first-child {
  padding-left: 1.25em !important;
}

#my-table tr th:first-child {
  padding-left: 1.25em !important;
}

.action-item-card {
  padding: 0px !important;
}

.edu-card {
  padding-left: 1rem;
  padding-right: 1rem;
}

.action-header {
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.my-header {
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.table-header {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 10px;
  /* font-weight: 600; */
}

.active-tab-hr {
  border-top: 3px #234372 solid;
  margin-top: 1rem;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}

.act-tab-dr {
  margin-top: -2rem !important;
}

.in-action {
  margin-right: 1.688em;
}

.action-table tbody {
  font-size: 0.875em;
}

.per-page {
  width: 4rem;
  height: 2.5rem;
  border: 1px solid #e2dcdc;
}

.per-page-cont {
  text-align: right;
  position: absolute;
  left: 50%;
  top: 0rem;
}

.pagination-con {
  font-size: 0.875em;
  padding-left: 2.5rem;
  padding-right: 1.25rem;
}

.tema-learning {
  margin-top: 2.5rem;
  border-radius: 6px;
}

.tr-date {
  color: #434343;
  margin-top: 1rem;
}

.tr-require {
  margin-left: 5px;
}

.tr-name {
  color: #234bac;
  margin-top: 0.4rem;
}

.details-cl {
  margin-top: 0.4rem;
  margin-left: 2em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cource-img {
  height: 100%;
}

.start-course-bt {
  background-color: #274ba7;
  border-color: #274ba7 !important;
  margin-top: 0.4rem;
}

.start-course-bt:hover {
  background-color: #258cf3 !important;
  border-color: #258cf3 !important;
}

.start-course-bt:active {
  background-color: #258cf3 !important;
  border-color: #258cf3 !important;
}

.start-course-bt:focus {
  box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

.nav-bt {
  margin-top: 1rem;
}

.nav-bt-div {
  width: 50%;
  align-items: center;
  cursor: pointer;
}

.tr-image {
  margin-top: 1rem;
}

.nav-mr {
  margin-right: 5px;
}

.nav-icons {
  background: #274ba7;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}

.team-learning-chart {
  width: 77%;
}

.view-team-learning {
  width: 23%;
  background-color: #f1fbff;
  text-align: center;
}

.pie-chart-con {
  width: 30%;
}

.team-learning-con {
  align-items: center;
  height: 12rem;
}

.pie-chart-con .chart__label {
  opacity: 0 !important;
}

.pie-chart-con .chart__line {
  opacity: 0 !important;
}

.bar-lables {
  align-items: center;
}

.completed-dot {
  background-color: #3bd761;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.bar-slice-info {
  text-align: center;
}

.slice-name-cp {
  color: #3bd761;
}

.slice-count {
  color: #606060;
}

.slice-name-due {
  color: #dc5656;
}

.slice-name-td {
  color: #e7dd09;
}

.due-dot {
  background-color: #dc5656;
}

.todo-dot {
  background-color: #e7dd09;
}

.next-view-ast {
  margin: auto;
}

.ast-img {
  width: 138px;
  margin-top: 4em;
}

.team-learninf-botm {
  border-bottom: 1px #f3f3f3 solid;
}

.view-more {
  width: 40%;
  text-align: center;
}

.go-action-card {
  margin-top: 4rem;
  background-color: #fff;
  border-radius: 6px;
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.po-action-items {
  border-top: 1px solid #e2e0e0;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1.25rem;
}

#bar-chart-tb th {
  display: none !important;
}

/* #bar-chart-tb tbody tr:nth-child(odd) {
    background-color: #FFF !important;
} */
.op-tabele-data {
  padding-left: 3rem;
  padding-right: 3rem;
}

#bar-chart-tb td {
  font-size: 0.8em !important;
  font-weight: 600;
  color: #313131;
}

#bar-chart-tb tr td:first-child {
  width: 1%;
}

.toggle-bt {
  background: none;
  border: none;
}

.toggle-bt:focus {
  color: #274ba7;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 0 rgb(255 255 255 / 50%);
}

.toggle-bt:hover {
  color: #274ba7;
  background-color: #fff;
  border-color: #fff;
}

.toggle-bt:active {
  color: #274ba7 !important;
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

#detail-table td {
  color: #313131;
  font-size: 0.875em !important;
}

#detail-table tr {
  color: #313131;
  font-size: 0.875em !important;
  background-color: #eff3ff !important;
}

#detail-table tr td:first-child {
  width: 17%;
  text-align: center !important;
}

#detail-table tr td {
  text-align: right;
}

.bar-chart-container {
  display: flex;
  align-items: center;
  border-bottom: 1px #eae5e5 solid;
  height: 3rem;
}

.bar-slices {
  width: 20%;
  color: #313131;
  text-align: center;
  word-break: break-all;
}

.location-dt {
  text-align: left;
}

.bar-divider {
  margin-bottom: 0 !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.location-lt {
  padding-left: 2em;
}

.bar-chart-detailed-con {
  background-color: #eff3ff;
  border-bottom: 1px #eae5e5 solid;
  height: 3rem;
}

.mr-2 {
  margin-right: 1rem;
}

.view-bottn {
  width: 6%;
}

.view-btn {
  width: 49px;
  height: 2rem;
  padding-top: 0.2rem;
}

.view-btm-con {
  width: 8%;
}

.gap-closure-header {
  margin-top: 1rem;
}

.action-item-row {
  margin-top: 3rem;
  border-radius: 6px;
}

.gap-closure-header-sub-con {
  width: 50%;
}

.gap-closure-header-action {
  flex-direction: row-reverse;
  margin-top: -1.1rem;
}

.table-view {
  border: 1px #274ba7 solid;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 50px 50px 0px;
  width: 40px;
  color: #274ba7;
  cursor: pointer;
}

.grap-view {
  border: 1px #274ba7 solid;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px 0px 0px 50px;
  width: 40px;
  color: #274ba7;
  cursor: pointer;
}

.active-view {
  background-color: #274ba7;
  color: #fff !important;
}

.print-view {
  margin-right: 1em;
  font-size: 18px;
}

.download-view {
  margin-right: 0.5em;
  font-size: 18px;
}

.download-view:hover,
.print-view:hover {
  background: #e3e8f7;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
}

.gp-hr {
  margin-top: -1.2rem !important;
}

.on-tran-tick {
  background: #109b10;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
}

.off-tran-tick {
  background: #d35757;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
}

.badge-cl {
  background-color: transparent !important;
}

.pillar-st {
  width: 5rem;
  height: 5rem;
  border-radius: 50px;
  text-align: center;
  line-height: 61px;
  color: #fff;
}

.on-pillar {
  background: #3a5824;
  /* background: #24693d;3a5824 */
}

.off-pillar {
  background: #c11a3b;
}

.non-pillar {
  background: #7c7c7c;
  opacity: 0.2;
}

.pillar-con {
  padding-left: 4em;
  padding-right: 4em;
  margin-top: 3em;
}

.pillar-tic {
  margin-top: -65px;
  margin-left: 38px;
}

.track-tic-con {
  text-align: center;
  margin-top: 1em;
}

.mr-off-tic {
  margin-left: 2em;
}

.team-bar-sm {
  display: none;
}

@media only screen and (max-width: 992px) {
  .pillar-tm {
    margin-top: 10px;
  }

  .education-row {
    margin-top: 1.5em;
  }

  .pagination-align {
    justify-content: left !important;
  }

  .team-learninf-botm {
    display: block !important;
  }

  .view-team-learning {
    width: 100% !important;
  }

  .pie-chart-con {
    width: 100%;
  }

  .bar-slice-info {
    display: none;
  }

  .team-learning-chart {
    width: 100% !important;
  }

  .team-bar-sm {
    display: flex;
  }
}

.acti-item-filter {
  /* width: 68%; */
  display: flex;
  margin-top: -5px;
  flex-direction: row-reverse;
  margin-left: 10%;
  position: absolute;
  right: 10px;
  top: 45px;
}

.funnel-con {
  font-size: 16px;
}

.form-check>input:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(../../assets/green-check.svg);
}

@media only screen and (max-width: 767px) {
  .per-page-cont {
    text-align: left;
  }
}

@media only screen and (max-width: 576px) {
  .gap-closure-header {
    display: block !important;
  }

  .gap-closure-header-action {
    width: 100% !important;
  }

  .gp-hr {
    margin-top: 0px !important;
  }
}

.custome-con {
  width: 50%;
  text-align: right;
}

.tab-nav {
  font-size: 0.838em;
  margin-top: 1em;
  margin-bottom: 10px;
}

.active-tab {
  font-weight: 600;
  color: #234372;
}

.active-tab-scroll-hr {
  border-top: 3px #274ba7 solid;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  margin-top: 1rem;
}

.opacity-zero {
  opacity: 0;
}

.education-tab {
  margin-top: 3rem;
}

.sub-menu {
  padding-left: 4.5em;
  padding-right: 4.5em;
  padding-top: 2rem;
  margin-top: -35px;
  background: #e5e6e8;
  border-radius: 25px;
  top: 60px;
}

.sub-menu-devider {
  margin-top: -2rem;
}

.sub-con {
  height: 3em;
  background-color: darkgoldenrod;
  /*position: sticky;*/
  top: 60px;
}

.body-container {
  margin-top: -10px;
  padding-bottom: 70px;
  padding-top: 0px;
}

.cource-act {
  align-items: center;
  margin-top: 3rem;
}

.detial-icon {
  font-size: 9px;
  margin-left: 2px;
}

.see-all-detais {
  margin-top: 3em;
  text-align: right;
}

.edu-col {
  margin-top: 2rem;
}

.from-dt {
  opacity: 0.7;
}

.gc-table {
  margin-top: 3em;
}

.my-tm-togg-con {
  border-radius: 50px;
  cursor: pointer;
  height: 21px;
  width: 22px;
  text-align: center;
  background-color: #eff3ff !important;
  border: 1px solid #274ba7 !important;
  display: flex;
  padding-left: 0.3rem;
  margin-right: 2rem;
}

.my-tm-togg-con:hover {
  background-color: #274ba727 !important;
}

.my-tm-togg-img {
  width: 0.9rem;
}

.po-action-items-tl {
  padding-bottom: 2rem;
}

.mt-1 {
  margin-top: 1rem !important;
}

.requires-action-item-card {
  display: inline-flex;
  padding: 0;
}

.plant-dd {
  width: 25%;
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.requires-action-header {
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.off-track-label {
  color: #c11a3b;
}

/deep/ .requires-action-table::-webkit-scrollbar {
  width: 10px;
  background: transparent;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

/deep/ .requires-action-table::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #234372;
}

/deep/ .modal-font {
  font-family: "BarlowM", sans-serif;
}

/deep/ .modal-header {
  background-color: #274ba7;
  color: #fff;
  font-size: 18px;
}

/deep/ .modal-title {
  font-size: 1.5rem;
}

/deep/ .modal-footer .btn-primary {
  background-color: #274ba7;
  border-color: #274ba7b8;
  font-size: 16px;
}

/deep/ .tabs {
  display: inline-flex;
}

/deep/ .nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  display: contents;
}

/deep/ .tab-content {
  margin-left: 30px;
}

/deep/ .nav-tabs::-webkit-scrollbar {
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  background: transparent;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/deep/ .nav-tabs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #234372;
}

/deep/ .nav-link {
  font-size: 16px;
  color: grey;
  overflow: hidden;
  width: 400px;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

/deep/ .nav-link.active {
  color: #234372;
}

/deep/ .nav-link:hover {
  color: #234372;
}
</style>
